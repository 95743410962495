import React, { useState } from "react"
import { css } from "@emotion/react"
import SEO from "../../components/bseo"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { Link, useScrollRestoration } from "gatsby"
import BookLayout from "../../components/BookLayout"
import BookNav from "../../components/BookNav"
import BookListItem from "../../components/BookListItem"

const Store = ({ data }) => {
  const {
    allAirtable: { edges },
  } = data

  const [searchTerm, setSearchTerm] = useState("")
  return (
    <BookLayout>
      <SEO title="Bookstore"></SEO>
      <div>
        <h1
          css={css`
            text-align: center;
            margin-top: 20px !important;
          `}
        >
          Bookstore
        </h1>
      </div>
      <div
        css={css`
          display: flex;
          justify-content: center;
          margin-top: 10px;
        `}
      >
        <input
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          type="text"
          placeholder="Search"
          css={css`
            padding: 5px;
            font-size: 14px;
            font-family: "Montserrat", sans-serif;
          `}
        ></input>
      </div>
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-row-gap: 30px;
          max-width: 1200px;
          margin: 30px auto;
          padding: 0 50px;
          @media screen and (max-width: 800px) {
            display: flex;
            flex-direction: column;
          }
          img {
            width: 80%;
            @media screen and (max-width: 800px) {
              width: 70%;
            }
          }

          a {
            all: unset;
          }
        `}
      >
        {edges
          .filter(({ node }) => {
            if (!searchTerm) return true
            let searchableText = node.data["Name"] + " " + node.data["Author"]
            searchableText = searchableText.toLowerCase()
            return searchableText.includes(searchTerm)
          })
          .map(({ node }) => (
            <BookListItem data={node.data} />
          ))}
      </div>
    </BookLayout>
  )
}

export const query = graphql`
  query BookStoreQuery {
    allAirtable(filter: { table: { eq: "books" } }) {
      edges {
        node {
          data {
            Name
            Author
            Description
            Image
          }
        }
      }
    }
  }
`

export default Store
